import BackEnd from '@/sys/BackEnd/BanckEnd'
import Unity from '@/sys/Model/Unity/Unity'
import Device from '@/sys/Model/Device/Device'

export default class DeviceDAO {

    static async createDevice(device: Device) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().createDevice(device);
                resolve("Dispositivo criado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_110: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async addDeviceUnity(unity: Unity, deviceList: Array<Device>) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().addDeviceUnity(unity, deviceList);
                resolve("Unidade adicionada com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_112: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readDevice(hardwareId: String) {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readDevice(hardwareId));
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_114: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readAllActiveDevices() {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readAllActiveDevices());
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_115: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readAllAvailableDevices() {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readAllAvailableDevices());
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_116: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readNotApprovedDevicesToken() {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readNotApprovedDevicesToken());
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_105: Favor checar sua conexão com a internet.");
            }
        })
    }

    static async readFireAlert(hardwareId: String, email: String) {
        return new Promise(async function(resolve,reject){
            try{
                let device = await BackEnd.function().readDevice(hardwareId);
                for (const user of device.unity.userList) {
                    if(user.email == email){
                        resolve(user.idFireAlertChecked);
                    }
                }
                resolve(null);
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_117: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async updateDevice(device: Device) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().updateDevice(device);
                resolve("Dispositivo atualizado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_118: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async updateFireAlert(hardwareId: String, email: String, alertId: Number){
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().updateFireAlert(hardwareId, email, alertId);
                resolve("Alerta de fogo atualizado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_122: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async deleteDevice(device: Device) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().deleteDevice(device);
                resolve("Dispositivo deletado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_123: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async removeDeviceUnity(devices: Array<Device>) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().removeDeviceUnity(devices);
                resolve("Unidade removida com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_125: Favor checar sua conexão com a internet.");
            }
        });
    }
}

export {DeviceDAO}