<template>
    <div class="device" :style="{backgroundColor: bodyColor}" ref="deviceDiv">
        <div class="description">
            <div class="titleDevice" :style="{backgroundColor: titleColor}">
                <label v-if="device.client != null" class="Name textSmall">{{device.client.name}}</label>
                <label v-else class="Name textSmall">{{'-'}}</label>
                <div class="fadeLongName" :class="{fadeLongNameOffline: !device.isOnline}"></div>
            </div>
            <div class="descriptionArea">
                <div class="descriptionsLabel textVerySmall lightGrey">{{device.type.name}}</div>
                <div v-if="device.unity != null" class="descriptionsLabel textVerySmall lightGrey">{{device.description}} - {{device.unity.name}}</div>
                <div v-else class="descriptionsLabel textVerySmall lightGrey">{{device.description}}</div>
                <div v-if="device.unity != null" class="descriptionsLabel textVerySmall lightGrey">{{device.unity.city}}-{{device.unity.state}}, {{device.unity.country.name}}</div>
                <div v-else class="descriptionsLabel textVerySmall lightGrey"><br></div>
                <div class="descriptionsLabel textVerySmall lightGrey">{{formatHardwareID(device.hardwareId)}}</div>
                <div class="fadeLongDescription" :class="{fadeLongDescriptionOffline: !device.isOnline}"></div>
            </div>
            <div class="status">
                <template v-if="isFHT()">
                    <img class="iconStatus" src="@/assets/icons/alert/fire2.svg" />
                    <label class="statusLabel">{{device.status.counters[statusCountIndex.DEVICES_ON_FIRE]}}</label>
                    <img class="iconStatus" src="@/assets/icons/alert/Fault.svg" />
                    <label class="statusLabel">{{device.status.counters[statusCountIndex.DEVICES_ON_FAILURE]}}</label>
                    <img class="iconStatus" src="@/assets/icons/alert/battery.svg" />
                    <label class="statusLabel">{{device.status.counters[statusCountIndex.DEVICES_ON_LOW_BATTERY]}}</label>
                    <img class="iconStatus" src="@/assets/icons/alert/supereye.svg" />
                    <label class="statusLabel">{{device.status.counters[statusCountIndex.DEVICES_ON_SUPERVISION]}}</label>
                    <img class="iconStatus" src="@/assets/icons/alert/manutence.svg" />
                    <label class="statusLabel">{{device.status.counters[statusCountIndex.DEVICES_ON_DISABLE]}}</label>
                </template>
            </div>
            <template v-if="isFirebeeDevice()">
                <img class="mainStatusIconShadow" src="@/assets/icons/alert/shadowStatus.svg" />
                <img class="mainStatusIcon" :src="imgSrc" />
            </template>
            <template v-else-if="isESP()">
                <img class="mainStatusIcon" :src="imgSrc" style="scale: .8;"/>
            </template>
        </div>
        <PopUpFire :device="device" @confirm="confirmFirePopUp()"></PopUpFire>
    </div>
</template>

<script>
    import PopUpFire from '@/views/PopUp/PopUpFire'
    import {Device} from '@/sys/Control/Device/DeviceControl';
    import {STATUS_COUNT_INDEX, SYSTEM_INFO_INDEX, CENTRAL_TYPE} from '@/sys/Model/Device/Device';

    export default {
        components:{ PopUpFire },
        methods:{
            isFHT(){ 
                return this.device.type.id <= CENTRAL_TYPE.MB15_8
            },
            isESP(){
                return this.device.type.id === CENTRAL_TYPE.GATEWAY;
            },
            isFirebeeDevice() { return this.device.type.id != CENTRAL_TYPE.GATEWAY },
            formatHardwareID(hardwareId){
                if(hardwareId.length > 16){
                    return '...' + hardwareId.substr(-16)
                }else{
                    return hardwareId
                }
            }
        },
        data() {
            return {
                imgSrc:null,
                statusCountIndex:STATUS_COUNT_INDEX,
                showPopUpFireAlert: false,
                alert: null,
                hardwareId: null,
                titleColor: '#1a1a22',
                bodyColor: '#36393f',
                titleColors: {on: '#1a1a22', off: '#525252'},
                bodyColors: {on: '#36393f', off: '#666666'},
                SYSTEM_INFO_INDEX: SYSTEM_INFO_INDEX
            }
        },
        props: {
            device:[Device,Object]
        },
        watch:{
            $props:{
                handler(){
                    if(!this.device.status){
                        this.device.status = { counters: [0,0,0,0,0,0,0,0,0,0,0,0,0,0]};
                    }else{
                        if(!this.device.status.counters){
                            this.device.status.counters = [0,0,0,0,0,0,0,0,0,0,0,0,0,0];
                        }
                    }

                    if(!this.device.isOnline){
                        this.imgSrc = this.isESP() ? require("@/assets/icons/noConnection.svg") : require("@/assets/icons/alert/Offline.svg");

                        this.titleColor = this.titleColors.off
                        this.bodyColor = this.bodyColors.off
                    }else{
                        this.titleColor = this.titleColors.on
                        this.bodyColor = this.bodyColors.on

                        if(this.isESP()){
                            if(this.device.systemInfo.fireAlert){
                                this.imgSrc = require("@/assets/icons/redFire.svg");
                            }else if(this.device.systemInfo.failureAlert){
                                this.imgSrc = require("@/assets/icons/warning.svg");
                            }else{
                                this.imgSrc = require("@/assets/icons/checked.svg");
                            }
                        }else{
                            if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FIRE]>0){
                                this.imgSrc = require("@/assets/icons/alert/fire.svg");
                            }else{
                                if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FAILURE]>0){
                                    this.imgSrc = require("@/assets/icons/alert/failure.svg");
                                }else{
                                    if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_LOW_BATTERY]>0){
                                        this.imgSrc = require("@/assets/icons/alert/lowBattery2.svg");
                                    }else{
                                        if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_SUPERVISION]>0){
                                            this.imgSrc = require("@/assets/icons/alert/supervision.svg");
                                        }else{
                                            if(this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_DISABLE]>0){
                                                this.imgSrc = require("@/assets/icons/alert/disabled2.svg");
                                            }else{
                                                this.imgSrc = require("@/assets/icons/alert/idle.svg");
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                deep:true,
                immediate:true
            }
        }
    }
</script>

<style scoped>
.mainStatusIconShadow{
    position: absolute;
    bottom: 8px;
    right: 13px;
    width: 65px;
}
.mainStatusIcon{
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 60px;
}
.titleDevice{
    position: relative;
    width: 100%;
    height: 35px;
    background-color: #1a1a22;
    border-radius: 5px 5px 0 0;
    padding-left: 8px;
    overflow: hidden;
}
.descriptionArea{
    position: relative;
    margin: 10px 0 0 10px;
    overflow: hidden;
}
.iconStatus{
    height: 16px;
}
.statusLabel{
    font-family: Arial, Helvetica, sans-serif;
    color: #808388;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 3px;
    margin-right: 5px;
}
.status{
    display: flex;
    flex-direction: row;
    margin: 5px;
    align-items: center;
    height: 16px;
}
.description{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    min-width: 170px;
    width: 170px;
    flex-basis: 170px;
    flex-grow: 1;
}
.descriptionsLabel{
    width: 100%;
    white-space: nowrap;
}
.Name{
    line-height: 37px;
    white-space: nowrap;
}
.device{
    position: relative;
    flex-grow: 1;
    width: 420px;
    flex-basis: 420px;
    margin: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center;
    background-color:#36393f;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.50);
}
.fadeLongName{
    position: absolute;
    top: 0;
    right: 0px;
    width: 50px;
    height: 100%;
    background-image: linear-gradient(to right, #1a1a2200, #1a1a22 80%);
}
.fadeLongNameOffline{
    background-image: linear-gradient(to right, #52525200, #525252 80%);
}
.fadeLongDescription{
    position: absolute;
    top: 0;
    right: 0px;
    width: 125px;
    height: 100%;
    background-image: linear-gradient(to right, #36393f00, #36393f 30%);
}
.fadeLongDescriptionOffline{
    background-image: linear-gradient(to right, #66666600, #666666 30%);
}
@media(max-width: 1450px){
    .device{
        margin: 5px;
        width: 320px;
        flex-basis: 320px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.50);
    }
    .titleDevice{
        height: 28px;
        border-radius: 4px 4px 0 0;
        padding-left: 6px;
    }
    .Name{
        line-height: 28px;
    }
    .mainStatusIconShadow{
        width: 55px;
        bottom: 4px;
    }
    .mainStatusIcon{
        width: 50px;
        bottom: 6px;
    }
    .statusLabel{
        font-size: 10px;
        margin-left: 3px;
        margin-right: 5px;
    }
    .iconStatus{
        height: 12px;
    }
    .status{
        height: 12px;
    }
}
@media(max-width: 825px){
    .mainStatusIconShadow{
        width: 55px;
    }
    .mainStatusIcon{
        width: 50px;
    }
}
@media(max-width: 600px){
    .device{
        margin: 5px 0;
        width: 300px
    }
}
@media (orientation: landscape) {
}

@media (orientation: portrait) {
}
</style>