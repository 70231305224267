<template>
  <div v-if="showPopup">
    <div class="arrowBalloon" ref="popUpArrow"></div>
    <div class="areaBalloon" ref="popUpDiv">
      <div class="areaIcon">
        <img class="fireIcon" src="@/assets/icons/strongFire.svg">
      </div>
      <div class="header textNormal">
        Alerta de Incêndio
      </div>
      <div class="modal-body">
        <div class="tablePosition">
          <table class="table textSmall">
            <tr>
              <td><label class="labelTable" style="padding-right: 20px">Dispositivo:</label></td>
              <td>{{device.status.fire.list[device.fireAlertIndex][STATUS_INFO_INDEX.DEVICE_NAME]}}</td>
            </tr>
            <tr>
              <td><label class="labelTable">Alerta:</label></td>
              <td>{{device.status.fire.list[device.fireAlertIndex][STATUS_INFO_INDEX.TYPE_STATUS]}}</td>
            </tr>
            <tr>
              <td><label class="labelTable">Planta:</label></td>
              <td>{{device.status.fire.list[device.fireAlertIndex][STATUS_INFO_INDEX.ZONE_NAME]}}</td>
            </tr>
              <tr>
              <td><label class="labelTable">Local:</label></td>
              <td>{{device.status.fire.list[device.fireAlertIndex][STATUS_INFO_INDEX.LOCATION]}}</td>
            </tr>
          </table>
        </div>
        <div class="fadeLongName"></div>
      </div>
      <div class="areaButton">
        <button class="btnFirePopup btnTypeSave" @click="confirm()">Ok</button>
        <button class="btnFirePopup btnTypeSave" style="margin-left:10px" @click="openRDP()">Abrir RDP</button>
      </div>
    </div>
  </div>
</template>

<script>
import {STATUS_INFO_INDEX} from '@/sys/Model/Device/Device'
import DeviceDAO from '@/sys/Control/Device/DeviceDAO'
import BackEnd from '@/sys/BackEnd/BanckEnd'

export default {
  props:{
    device: Object
  },
  methods:{
    confirm(){
      DeviceDAO.updateFireAlert(this.device.hardwareId, BackEnd.user.customData.email, this.device.status.fire.list[this.device.fireAlertIndex][STATUS_INFO_INDEX.ID])
      this.$emit('close')
    },
    openRDP(){
      DeviceDAO.updateFireAlert(this.device.hardwareId, BackEnd.user.customData.email, this.device.status.fire.list[this.device.fireAlertIndex][STATUS_INFO_INDEX.ID])
      this.$emit('close')
    }
  },
  watch:{
    $props:{
      handler(){
        //console.log(this.device)
        this.showPopup = this.device.fireAlert;
      },
      deep:true,
      immediate:true
    }
  },
  data(){
    return{
      STATUS_INFO_INDEX: STATUS_INFO_INDEX,
      showPopup: false
    }
  }
}
</script>

<style scoped>
  .btnFirePopup{
    padding: 12px;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    text-decoration:none;
    text-align: center;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    width: 160px;
    font-size: 19px;
  }
  .fireIcon{
    width: 70px;
  }
  .modal-body{
    position: relative;
  }
  .areaBalloon{
    position: absolute;
    right: 0;
    left: 0;
    top: 130%;
    margin: 0 auto;
    width: 420px;
    height: 375px;
    border-radius: 18px;
    background-color: #292d32;
    z-index: 15;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  }
  .arrowBalloon{
    position: absolute;
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    top: 100%;
    margin: 0 auto;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 60px solid #292d32;
    z-index: 16;
  }
  .areaIcon{
    position: absolute;
    width: 70px;
    height: 100px;
    top: 25px;
    right: 0;
    left: 0;
    margin: 0 auto
  }
  .header{
    margin: 110px 0 15px 0;
    text-align: center;
  }
  .tablePosition{
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
    /* background-color: #1e2227; */
  }
  .table{
    border-collapse: collapse;
  }
  .table td{
    height: 35px;
    padding-left: 10px;
    white-space: nowrap;
  }
  .fadeLongName{
    position: absolute;
    top: 0;
    right: 12px;
    width: 40px;
    height: 100%;
    /* background-image: linear-gradient(to right, rgba(0,0,0,0), #1e2227 80%); */
    background-image: linear-gradient(to right, rgba(0,0,0,0), #292d32 80%);
  }
  .labelTable{
    text-align: right;
    color: rgb(158, 158, 158);
  }
  .areaButton{
    position: absolute;
    bottom: 20px;
    width: 330px;
    right: 0;
    left: 0;
    margin: 0 auto
  }
  .iconButtonFireAlert{
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 36px
  }
  .shadowSmooth{
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
  }
  .blockScroll{
    position: fixed;
    z-index: 13;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }
  @media(max-width: 720px){
    .arrowBalloon{
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 40px solid #292d32;
    }
    .areaIcon{
      width: 50px;
      height: 50px;
      top: 15px;
    }
    .fireIcon{
      width: 50px
    }
    .header{
      margin: 75px 0 5px 0;
    }
    .areaBalloon{
      width: 280px;
      height: 260px;
    }
    .table td{
      height: 25px;
      padding-left: 10px;
    }
    .areaButton{
      bottom: 10px;
      width: 210px;
    }
    .btnFirePopup{
      text-decoration: none;
      width: 100px;
      font-size: 12px;
      padding: 10px;
    }
  }
</style>
