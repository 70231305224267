import { ObjectId } from "bson"
/***********************************************************
*                        Client                          *
***********************************************************/
export class DeviceClient{
    public _id:ObjectId;
    public name:String;

    constructor(){
    }
}

/***********************************************************
*                        Unity                         *
***********************************************************/
export class UserPermissions{
    public _id:ObjectId;
    public realmId:String;
    public name:String;
    public email:String;
    public accessLevel:Number;
    public permissions:{
        system: Array<Number>,
        services: Array<Number>
    };
    public addFromClient:Boolean;
    public idFireAlertChecked:number=0;//Registra o ultimo alerta de fogo que o cliente já visualizou, para evitar que mesmo cliente visulize o mesmo alerta mais de uma vez.

    constructor(){
    }
}

export class Country{
    public id:Number;
    public name:String;

    constructor(){}
}

export class DeviceUnity{
    public _id:ObjectId;
    public cnpj:String;
    public name:String;
    public city:String;
    public state:String;
    public country:Country;
    public userList:Array<UserPermissions>;

    constructor(){
    }
}


/***********************************************************
 *                    System Info                          *
 * ********************************************************/
export enum SYSTEM_INFO_INDEX{
    CPU_USAGE,
    RAM_USAGE,
    HD_USAGE,
    CORE_TEMPERATURE,
    ETHERNET,
    WIFI,
    MOBILLE,
    TAMPER_SWITCH,
    CENTRAL,
    BATTERY,
    LOGIN_INFO,
    DATE,
}

export class DeviceSytemInfo extends Array{
    constructor(){
        super();
        this.concat([0.0,0.0,0.0,0.0,false,false,false,false,false,{},"",""]);
    }
}

/***********************************************************
 *                        Status                           *
 * ********************************************************/
export enum STATUS_TYPES{
    COUNTERS="Counters",
    FIRE="Fire",
    NO_SIGNAL="NoSignal",
    FAILURE="Failure",
    LOW_BATTERY="LowBattery",
    SUPERVISION="Supervision",
    DISABLE="Disable",
}

enum STATUS_COUNT_INDEX{
    DEVICE_COUNT,
    DEVICES_ON_FIRE,
    DEVICES_ON_NO_SIGNAL,
    DEVICES_ON_FAILURE,
    DEVICES_ON_LOW_BATTERY,
    DEVICES_ON_SUPERVISION,
    DEVICES_ON_DISABLE,
    ZONE_COUNT,
    ZONES_ON_FIRE,
    ZONES_ON_NO_SIGNAL,
    ZONES_ON_FAILURE,
    ZONES_ON_LOW_BATERRY,
    ZONES_ON_SUPERVISION,
    ZONES_ON_DISABLE,
    DATE
}

enum NETWORK_STATUS{
    OFF,
    ON,
    FAILURE
}

enum CENTRAL_TYPE{
    FHT15_2 = 1,
    FHT15_8 = 2,
    MB15_2 = 3,
    MB15_8 = 4,
    CLE = 5,
    CLX = 6,
    GATEWAY = 7,
    FHT7_2 = 8,
    FHT7_8 = 9,
}

export class DeviceStatusCounters extends Array{
    constructor(){
        super();
        this.concat([0,0,0,0,0,0,0,0,0,0,0,0,0,0]);//ver STATUS_INDEX
    }
}

enum STATUS_INFO_INDEX{
    ID,
    DEVICE_NAME,
    LOCATION,
    ZONE_NAME,
    TYPE_STATUS,
    STATUS,
    DATE
}

export class DeviceStatusInfo extends Array{
    constructor(){
        super();
        this.concat([0,"","","","","",""]);//ver ALERT_INDEX
    }
}

export class DeviceGenericStatus{
    public count:number=0;
    public silenceCount:number=0;
    public list:Array<DeviceStatusInfo>=[];//verificar ALERT_INDEX
    constructor(){
    }
}

export class DeviceStatus{
    public counters:DeviceStatusCounters=new DeviceStatusCounters();
    public fire:DeviceGenericStatus=new DeviceGenericStatus();
    public NoSignal:DeviceGenericStatus=new DeviceGenericStatus();
    public failure:DeviceGenericStatus=new DeviceGenericStatus();
    public lowBattery:DeviceGenericStatus=new DeviceGenericStatus();
    public supervision:DeviceGenericStatus=new DeviceGenericStatus();
    public disable:DeviceGenericStatus=new DeviceGenericStatus();
    constructor(){
    }
}

/***********************************************************
*                      Activated GROUPS                    *
************************************************************/
export enum ACTIVTED_GROUPS_INDEX{
    ID,
    NAME,
    STATUS,
    USER,
    DATE,
}

export class DeviceGroupInfo extends Array{
    constructor(){
        super();
        this.concat([0,"","","",""]);//ver GRUPS_ACTIVES_INDEX
    }
}

export class DeviceActivatedGroups{
    public count:number=0;
    public list:Array<DeviceGroupInfo>=[];//verificar ALERT_INDEX
    constructor(){
    }
}

/***********************************************************
*                      Device                              *
************************************************************/

export class Type{
    public id:Number;
    public name:String;
    constructor(){}
}

export default class Device{
    public _id:ObjectId;
    public client:DeviceClient;
    public unity:DeviceUnity;
    public type:Type;
    public hardwareId:String;
    public description:String;
    public gpsCoordinates:String;
    public systemInfo:DeviceSytemInfo;
    public status:DeviceStatus;
    public activatedGroups:DeviceActivatedGroups;
    public createdAt:Date;
    public updatedAt:Date;
    public deletedAt:Date;

    constructor(client:DeviceClient, unity:DeviceUnity, type:Type, hardwareId:String, description:String, gpsCoordinates:String, systemInfo:DeviceSytemInfo, 
        status:DeviceStatus, activatedGroups:DeviceActivatedGroups){

        this.client=client;
        this.unity=unity;
        this.type=type;
        this.hardwareId=hardwareId;
        this.description=description;
        this.gpsCoordinates=gpsCoordinates;
        this.systemInfo=systemInfo;
        this.status=status;
        this.activatedGroups=activatedGroups;
        this.createdAt=new Date();
        this.updatedAt=new Date();
        this.deletedAt=null;
    }
}

export {Device, STATUS_COUNT_INDEX,STATUS_INFO_INDEX, NETWORK_STATUS, CENTRAL_TYPE};

/*****************************Samples of System Info***********************************************************************************************
* topic: Firebee/Central/central1/SystemInfo
* sample1: mosquitto_pub -t Firebee/Central/central1/SystemInfo -m '[10,20,30,40,false,false,true,false,true,{"battery1":"13.65V","battery2":"13.65V"},"2021-03-25 09:30:34",""]' //10% de uso de CPU, 20% de uso de memoria, 30% de uso do HD, 40ºC de temperatura, apenas conexão mobile,tamperswitch ok,rede ac OK, usuario deslogado
* sample2: mosquitto_pub -t Firebee/Central/central1/SystemInfo -m '[20,30,40,50,false,false,false,true,true,{"battery1":"13.65V","battery2":"13.65V"},"2021-03-25 09:30:34","Tulio"]' //20% de uso de CPU, 30% de uso de memoria, 40% de uso do HD, 50ºC de temperatura, apenas conexão wifi,tamperswitch ativo,rede ac OK, usuario Tulio logado
* sample3: mosquitto_pub -t Firebee/Central/central1/SystemInfo -m '[30,40,50,60,true,false,false,false,false,{"battery1":"13.01V","battery2":"13.02V"},"2021-03-25 09:30:34","Tulio"]' //30% de uso de CPU, 40% de uso de memoria, 50% de uso do HD, 60ºC de temperatura, apenas conexão ethernet,tamperswitch ok,rede ac failure, usuario Tulio Logado
* sample4: mosquitto_pub -t Firebee/Central/central1/SystemInfo -m '[30,40,50,60,true,true,true,false,,true,{"battery1":"13.65V","battery2":"13.65V"},"2021-03-25 09:30:34",""]' //30% de uso de CPU, 40% de uso de memoria, 50% de uso do HD, 60ºC de temperatura,todas as conexões ativas,tamperswitch ok,rede ac OK, usuario deslogado
********************************************************************************************************************************************/

/*****************************Samples of status***********************************************************************************************
* topic: Firebee/Central/central1/Status/Counters
* sample1: mosquitto_pub -t Firebee/Central/central1/Status/Counters -m '[1000,2,0,0,0,0,0,40,1,0,0,0,0,0,"2021-03-25 09:30:34"]' //1000 dispositivos 2 com alerta de fogo, 40 plantas e 1 com alerta de fogo
* sample2: mosquitto_pub -t Firebee/Central/central1/Status/Counters -m '[1000,0,3,0,0,0,0,40,0,2,0,0,0,0,"2021-03-25 09:30:34"]' //1000 dispositivos 3 com alerta de sinal, 40 plantas e 2 com alerta de sinal
* sample3: mosquitto_pub -t Firebee/Central/central1/Status/Counters -m '[1000,0,0,4,0,0,0,40,0,0,3,0,0,0,"2021-03-25 09:30:34"]' //1000 dispositivos 4 com alerta de falha, 40 plantas e 3 com alerta de falha
* sample4: mosquitto_pub -t Firebee/Central/central1/Status/Counters -m '[1000,0,0,0,5,0,0,40,0,0,0,4,0,0,"2021-03-25 09:30:34"]' //1000 dispositivos 5 com alerta de bateria, 40 plantas e 4 com alerta de bateria
* sample5: mosquitto_pub -t Firebee/Central/central1/Status/Counters -m '[1000,0,0,0,0,6,0,40,0,0,0,0,5,0,"2021-03-25 09:30:34"]' //1000 dispositivos 6 com alerta de supervisão, 40 plantas e 5 com alerta de supervisão
* sample6: mosquitto_pub -t Firebee/Central/central1/Status/Counters -m '[1000,0,0,0,0,0,7,40,0,0,0,0,0,6,"2021-03-25 09:30:34"]' //1000 dispositivos 7 com alerta de disabilitado, 40 plantas e 6 com alerta de disabilitado
********************************************************************************************************************************************/

/*****************************Samples of Alerts of Fire***********************************************************************************************
* topic: Firebee/Central/central1/Status/Fire
* sample1: mosquitto_pub -t Firebee/Central/central1/Status/Fire -m '{"count":0,"silenceCount":0,"list":[]}' //0 alerta, 0 silenciado, lista vazia
* sample2: mosquitto_pub -t Firebee/Central/central1/Status/Fire -m '{"count":1,"silenceCount":0,"list":[[188,"TUL_WBDFO0001","Recepição","Terreo","Fumaça Detectada","Ativo","2021-03-25 09:30:34"]]}' //1 alerta, 0 silenciado, lista com um alerta ver lista de index do Alerta
* sample3: mosquitto_pub -t Firebee/Central/central1/Status/Fire -m '{"count":1,"silenceCount":1,"list":[[189,"TUL_WBDFO0001","Recepição","Terreo","Fumaça Detectada","Silenciado","2021-03-25 09:30:34"]]}' //1 alerta, 1 silenciado, lista com alerta
* sample4: mosquitto_pub -t Firebee/Central/central1/Status/Fire -m '{"count":2,"silenceCount":1,"list":[[190,"TUL_WBDFO0002","Recepição","Terreo","Fumaça Detectada","Ativo","2021-03-25 09:30:34"],[189,"TUL_WBDFO0001","Recepição","Terreo","Fumaça Detectada","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 1 silenciado, lista com alerta
* sample5: mosquitto_pub -t Firebee/Central/central1/Status/Fire -m '{"count":2,"silenceCount":2,lists=[[191,"TUL_WBDFO0002","Recepição","Terreo","Fumaça Detectada","Silenciado","2021-03-25 09:30:34"],[189,"TUL_WBDFO0001","Recepição","Terreo","Fumaça Detectada","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 2 silenciado, lista com alerta
* sample6: mosquitto_pub -t Firebee/Central/central1/Status/Fire -m '{"count":2,"silenceCount":2,"list":[[193,"TUL_WBDFO0004","Recepição","Terreo","Fumaça Detectada","Ativo","2021-03-25 09:30:34"],[192,"TUL_WBDFO0003","Recepição","Terreo","Fumaça Detectada","Ativo","2021-03-25 09:30:34"]]}' //2 alerta, 0 silenciado, lista com alerta
********************************************************************************************************************************************/

/*****************************Samples of Alerts of Signal***********************************************************************************************
* topic: Firebee/Central/central1/Status/NoSignal
* sample1: mosquitto_pub -t Firebee/Central/central1/Status/NoSignal -m '{"count":0,"silenceCount":0,"list":[]}' //0 alerta, 0 silenciado, lista vazia
* sample2: mosquitto_pub -t Firebee/Central/central1/Status/NoSignal -m '{"count":1,"silenceCount":0,"list":[[288,"TUL_WBDFO0001","Recepição","Terreo","Sem Sinal","Ativo","2021-03-25 09:30:34"]]}' //1 alerta, 0 silenciado, lista com um alerta ver lista de index do Alerta
* sample3: mosquitto_pub -t Firebee/Central/central1/Status/NoSignal -m '{"count":1,"silenceCount":1,"list":[[289,"TUL_WBDFO0001","Recepição","Terreo","Sem Sinal","Silenciado","2021-03-25 09:30:34"]]}' //1 alerta, 1 silenciado, lista com alerta
* sample4: mosquitto_pub -t Firebee/Central/central1/Status/NoSignal -m '{"count":2,"silenceCount":1,"list":[[290,"TUL_WBDFO0002","Recepição","Terreo","Sem Sinal","Ativo","2021-03-25 09:30:34"],[289,"TUL_WBDFO0001","Recepição","Terreo","Sem Sinal","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 1 silenciado, lista com alerta
* sample5: mosquitto_pub -t Firebee/Central/central1/Status/NoSignal -m '{"count":2,"silenceCount":2,"list":[[291,"TUL_WBDFO0002","Recepição","Terreo","Sem Sinal","Silenciado","2021-03-25 09:30:34"],[289,"TUL_WBDFO0001","Recepição","Terreo","Sem Sinal","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 2 silenciado, lista com alerta
* sample6: mosquitto_pub -t Firebee/Central/central1/Status/NoSignal -m '{"count":2,"silenceCount":2,"list":[[293,"TUL_WBDFO0004","Recepição","Terreo","Sem Sinal","Ativo","2021-03-25 09:30:34"],[292,"TUL_WBDFO0003","Recepição","Terreo","Sem Sinal","Ativo","2021-03-25 09:30:34"]]}' //2 alerta, 0 silenciado, lista com alerta
********************************************************************************************************************************************/

/*****************************Samples of Alerts of Failure***********************************************************************************************
* topic: Firebee/Central/central1/Status/Failure
* sample1: mosquitto_pub -t Firebee/Central/central1/Status/Failure -m '{"count":0,"silenceCount":0,"list":[]}' //0 alerta, 0 silenciado, lista vazia
* sample2: mosquitto_pub -t Firebee/Central/central1/Status/Failure -m '{"count":1,"silenceCount":0,"list":[[388,"TUL_WBDFO0001","Recepição","Terreo","Dispositivo Removido","Ativo","2021-03-25 09:30:34"]]}' //1 alerta, 0 silenciado, lista com um alerta ver lista de index do Alerta
* sample3: mosquitto_pub -t Firebee/Central/central1/Status/Failure -m '{"count":1,"silenceCount":1,"list":[[389,"TUL_WBDFO0001","Recepição","Terreo","Dispositivo Removido","Silenciado","2021-03-25 09:30:34"]]}' //1 alerta, 1 silenciado, lista com alerta
* sample4: mosquitto_pub -t Firebee/Central/central1/Status/Failure -m '{"count":2,"silenceCount":1,"list":[[390,"TUL_WBDFO0002","Recepição","Terreo","Dispositivo Removido","Ativo","2021-03-25 09:30:34"],[389,"TUL_WBDFO0001","Recepição","Terreo","Dispositivo Removido","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 1 silenciado, lista com alerta
* sample5: mosquitto_pub -t Firebee/Central/central1/Status/Failure -m '{"count":2,"silenceCount":2,"list":[[391,"TUL_WBDFO0002","Recepição","Terreo","Dispositivo Removido","Silenciado","2021-03-25 09:30:34"],[389,"TUL_WBDFO0001","Recepição","Terreo","Dispositivo Removido","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 2 silenciado, lista com alerta
* sample6: mosquitto_pub -t Firebee/Central/central1/Status/Failure -m '{"count":2,"silenceCount":2,"list":[[393,"TUL_WBDFO0004","Recepição","Terreo","Dispositivo Removido","Ativo","2021-03-25 09:30:34"],[392,"TUL_WBDFO0003","Recepição","Terreo","Dispositivo Removido","Ativo","2021-03-25 09:30:34"]]}' //2 alerta, 0 silenciado, lista com alerta
********************************************************************************************************************************************/

/*****************************Samples of Alerts of Low Battery***********************************************************************************************
* topic: Firebee/Central/central1/Status/LowBattery
* sample1: mosquitto_pub -t Firebee/Central/central1/Status/LowBattery -m '{"count":0,"silenceCount":0,"list":[]}' //0 alerta, 0 silenciado, lista vazia
* sample2: mosquitto_pub -t Firebee/Central/central1/Status/LowBattery -m '{"count":1,"silenceCount":0,"list":[[488,"TUL_WBDFO0001","Recepição","Terreo","Bateria esgotada","Ativo","2021-03-25 09:30:34"]]}' //1 alerta, 0 silenciado, lista com um alerta ver lista de index do Alerta
* sample3: mosquitto_pub -t Firebee/Central/central1/Status/LowBattery -m '{"count":1,"silenceCount":1,"list":[[489,"TUL_WBDFO0001","Recepição","Terreo","Bateria esgotada","Silenciado","2021-03-25 09:30:34"]]}' //1 alerta, 1 silenciado, lista com alerta
* sample4: mosquitto_pub -t Firebee/Central/central1/Status/LowBattery -m '{"count":2,"silenceCount":1,"list":[[490,"TUL_WBDFO0002","Recepição","Terreo","Bateria esgotada","Ativo","2021-03-25 09:30:34"],[489,"TUL_WBDFO0001","Recepição","Terreo","Bateria esgotada","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 1 silenciado, lista com alerta
* sample5: mosquitto_pub -t Firebee/Central/central1/Status/LowBattery -m '{"count":2,"silenceCount":2,"list":[[491,"TUL_WBDFO0002","Recepição","Terreo","Bateria esgotada","Silenciado","2021-03-25 09:30:34"],[489,"TUL_WBDFO0001","Recepição","Terreo","Bateria esgotada","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 2 silenciado, lista com alerta
* sample6: mosquitto_pub -t Firebee/Central/central1/Status/LowBattery -m '{"count":2,"silenceCount":2,"list":[[493,"TUL_WBDFO0004","Recepição","Terreo","Bateria esgotada","Ativo","2021-03-25 09:30:34"],[492,"TUL_WBDFO0003","Recepição","Terreo","Bateria esgotada","Ativo","2021-03-25 09:30:34"]]}' //2 alerta, 0 silenciado, lista com alerta
********************************************************************************************************************************************/

/*****************************Samples of Alerts of Supervision***********************************************************************************************
* topic: Firebee/Central/central1/Status/Supervision
* sample1: mosquitto_pub -t Firebee/Central/central1/Status/Supervision -m '{"count":0,"silenceCount":0,"list":[]}' //0 alerta, 0 silenciado, lista vazia
* sample2: mosquitto_pub -t Firebee/Central/central1/Status/Supervision -m '{"count":1,"silenceCount":0,"list":[[588,"TUL_WBPAN0001","Recepição","Terreo","Panic","Ativo","2021-03-25 09:30:34"]]}' //1 alerta, 0 silenciado, lista com um alerta ver lista de index do Alerta
* sample3: mosquitto_pub -t Firebee/Central/central1/Status/Supervision -m '{"count":1,"silenceCount":1,"list":[[589,"TUL_WBPAN0001","Recepição","Terreo","Panic","Silenciado","2021-03-25 09:30:34"]]}' //1 alerta, 1 silenciado, lista com alerta
* sample4: mosquitto_pub -t Firebee/Central/central1/Status/Supervision -m '{"count":2,"silenceCount":1,"list":[[590,"TUL_WBPAN0002","Recepição","Terreo","Panic","Ativo","2021-03-25 09:30:34"],[589,"TUL_WBPAN0001","Recepição","Terreo","Panic","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 1 silenciado, lista com alerta
* sample5: mosquitto_pub -t Firebee/Central/central1/Status/Supervision -m '{"count":2,"silenceCount":2,"list":[[591,"TUL_WBPAN0002","Recepição","Terreo","Panic","Silenciado","2021-03-25 09:30:34"],[589,"TUL_WBPAN0001","Recepição","Terreo","Panic","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 2 silenciado, lista com alerta
* sample6: mosquitto_pub -t Firebee/Central/central1/Status/Supervision -m '{"count":2,"silenceCount":2,"list":[[593,"TUL_WBPAN0004","Recepição","Terreo","Panic","Ativo","2021-03-25 09:30:34"],[592,"TUL_WBPAN0003","Recepição","Terreo","Panic","Ativo","2021-03-25 09:30:34"]]}' //2 alerta, 0 silenciado, lista com alerta
********************************************************************************************************************************************/

/*****************************Samples of Alerts of Disable***********************************************************************************************
* topic: Firebee/Central/central1/Status/Disable
* sample1: mosquitto_pub -t Firebee/Central/central1/Status/Disable -m '{"count":0,"silenceCount":0,"list":[]}' //0 alerta, 0 silenciado, lista vazia
* sample2: mosquitto_pub -t Firebee/Central/central1/Status/Disable -m '{"count":1,"silenceCount":0,"list":[[688,"TUL_WBDFO0001","Recepição","Terreo","Disable","Ativo","2021-03-25 09:30:34"]]}' //1 alerta, 0 silenciado, lista com um alerta ver lista de index do Alerta
* sample3: mosquitto_pub -t Firebee/Central/central1/Status/Disable -m '{"count":1,"silenceCount":1,"list":[[689,"TUL_WBDFO0001","Recepição","Terreo","Disable","Silenciado","2021-03-25 09:30:34"]]}' //1 alerta, 1 silenciado, lista com alerta
* sample4: mosquitto_pub -t Firebee/Central/central1/Status/Disable -m '{"count":2,"silenceCount":1,"list":[[690,"TUL_WBDFO0002","Recepição","Terreo","Disable","Ativo","2021-03-25 09:30:34"],[689,"TUL_WBDFO0001","Recepição","Terreo","Disable","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 1 silenciado, lista com alerta
* sample5: mosquitto_pub -t Firebee/Central/central1/Status/Disable -m '{"count":2,"silenceCount":2,"list":[[691,"TUL_WBDFO0002","Recepição","Terreo","Disable","Silenciado","2021-03-25 09:30:34"],[689,"TUL_WBDFO0001","Recepição","Terreo","Disable","Silenciado","2021-03-25 09:30:34"]]}' //2 alerta, 2 silenciado, lista com alerta
* sample6: mosquitto_pub -t Firebee/Central/central1/Status/Disable -m '{"count":2,"silenceCount":2,"list":[[693,"TUL_WBDFO0004","Recepição","Terreo","Disable","Ativo","2021-03-25 09:30:34"],[692,"TUL_WBDFO0003","Recepição","Terreo","Disable","Ativo","2021-03-25 09:30:34"]]}' //2 alerta, 0 silenciado, lista com alerta
********************************************************************************************************************************************/

/*****************************Samples of Activated Groups***********************************************************************************************
* topic: /Firebee/Central/central1/ActivatedGroups
* sample1: mosquitto_pub -t Firebee/Central/central1/ActivatedGroups -m '{"count":0,"list":[]}' //0 grupos ativos, lista vazia
* sample2: mosquitto_pub -t Firebee/Central/central1/ActivatedGroups -m '{"count":1,"list":[[788,"Principal","Grupo Ativado Manualmente","Tulio","2021-03-25 09:55:26"]]}' //1 grupo ativo, lista com grupos ativos
* sample3: mosquitto_pub -t Firebee/Central/central1/ActivatedGroups -m '{"count":1,"list":[[789,"Principal","Grupo Ativado Automaticamente","","2021-03-25 09:55:26"]]}' //1 grupo ativo, lista com grupos ativos
* sample2: mosquitto_pub -t Firebee/Central/central1/ActivatedGroups -m '{"count":2,"list":[[791,"Principal","Grupo Ativado Automaticamente","","2021-03-25 09:55:26"],[790,"Principal","Grupo Ativado Manualmente","Tulio","2021-03-25 09:55:26"]]}' //2 grupos ativos, lista com grupos ativos
********************************************************************************************************************************************/
