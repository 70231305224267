import BackEnd, { ObjectId } from '../../BackEnd/BanckEnd'
import Device from '../../Model/Device/Device'
import {AccessLevel} from '@/sys/Model/Permissions/AccessLevel'

export default class DeviceControl {
    static listOfDevices:Array<Device> = [];
    static devicesLoaded:Boolean = false;

    static getListOfDevices(onLoadComplete:(success:Boolean)=>void, realmId: String):Array<Device>{
        try{
            this.listOfDevices = [];
            if(!this.devicesLoaded){
                let accessLevel = BackEnd.getCurrentUserAccess();
                if(accessLevel > AccessLevel.VENDAS) {
                    
                    BackEnd.function().readDevicesByRealmId(realmId).then(result=>{
                        
                        result.forEach(element=>{
                            this.listOfDevices.push(element);
                        });
                        BackEnd.syncToCollection("Device",{"unity.userList": {$elemMatch: {realmId: realmId}}, deletedAt: null}, this.listOfDevices);
                        onLoadComplete(true);
                    }).catch(e=>{
                        console.log(e);
                        this.devicesLoaded=false;
                        onLoadComplete(false);
                    });
                } else {
                    BackEnd.function().readAllActiveDevices().then(result=>{
                        
                        result.forEach(element=>{
                            this.listOfDevices.push(element);
                        });
                        BackEnd.syncToCollection("Device",{deletedAt: null}, this.listOfDevices);
                        onLoadComplete(true);
                    }).catch(e=>{
                        console.log(e);
                        this.devicesLoaded=false;
                        onLoadComplete(false);
                    });
                }
            }else{
                console.log("Call onLoadComplete");
                onLoadComplete(true);
            }
        }catch (e){
            console.log(e);
            this.devicesLoaded=false;
            onLoadComplete(false);
        }
        return this.listOfDevices;
    }
};

export {DeviceControl,Device};
