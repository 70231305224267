<template>
    <div class="ResponsiveContentBoard">
        <div class="titleArea">
            <div class="titleContainer">
                <img class="titleBackgound" src="@/assets/icons/titleBackgound.svg"/>
                <div class="titleText textNormal">
                    Lista de Centrais
                </div>
            </div>
            <div class="inputContainer">
                <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="deviceSearch">
                <img class="searchIcon" src="@/assets/icons/outros/search2.svg"/>
            </div>
        </div>
        <div v-for="device in deviceListFilter" :key="device.id" class="Device" :class="{DeviceWithAlert: device.fireAlert}">
            <DeviceCardView :device="device" :id="'device-' + device.hardwareId"/>
            <router-link v-if="device.isOnline" class="deviceRouterLink" :to="{ name:'AcessType', params: { device:device }}"></router-link>
        </div>
        <div class="FakeDevice"/>
        <div class="FakeDevice"/>
        <div class="FakeDevice"/>
        <div class="FakeDevice"/>
        <PopUp msg="Carregando lista de dispositivos..." :show="load" type="Loader"/>
        <PopUp msg="Verrifique sua conexão..." :show="fault" type="Fault" :onOk="ok()"/>
    </div>
</template>

<script>
    import DeviceCardView from './DeviceCardView.vue'
    import PopUp from '@/views/PopUp/PopUp.vue'
    import DeviceControl from '@/sys/Control/Device/DeviceControl'
    import BackEnd from '@/sys/BackEnd/BanckEnd'
    import {STATUS_COUNT_INDEX, CENTRAL_TYPE} from '@/sys/Model/Device/Device'

    export default {
        components:{DeviceCardView, PopUp},
        data: function(){
            return {
                load:true,
                fault:false,
                listOfDevices:DeviceControl.getListOfDevices((result)=>{
                    this.$nextTick(()=>{
                        this.load=false;
                        if(!result){
                            this.fault=true;
                        }
                    });
                }, BackEnd.getCurrentUserId()),
                deviceSearch: ''
            }
        },
        computed:{
            deviceListFilter(){
                let deviceFireAlert = [], deviceFailureAlert = [], deviceBatteryAlert = [], deviceSupervisionlert = [], deviceDisabledAlert = []
                let sortedList, offline = [], otherDevices = [], deviceFHTWithoutStatus = []

                for (const device of this.listOfDevices) {
                                       
                    if(device.isOnline){
                        if(device.type.id <= CENTRAL_TYPE.MB15_8 || device.type.id == CENTRAL_TYPE.FHT7_2 || device.type.id == CENTRAL_TYPE.FHT7_8){
                            if(device.status){
                                if(device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FIRE] > 0){
                                    deviceFireAlert.push(device)
                                    continue
                                }
                                if(device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FAILURE] > 0){
                                    deviceFailureAlert.push(device)
                                    continue
                                }
                                if(device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_LOW_BATTERY] > 0){
                                    deviceBatteryAlert.push(device)
                                    continue
                                }
                                if(device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_SUPERVISION] > 0){
                                    deviceSupervisionlert.push(device)
                                    continue
                                }
                                if(device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_DISABLE] > 0){
                                    deviceDisabledAlert.push(device)
                                    continue
                                }
                            }
                            deviceFHTWithoutStatus.push(device)
                            continue
                        }
                        otherDevices.push(device)
                    }else{
                        offline.push(device)
                    }
                }

                sortedList = deviceFireAlert.concat(deviceFailureAlert)
                sortedList = sortedList.concat(deviceBatteryAlert)
                sortedList = sortedList.concat(deviceSupervisionlert)
                sortedList = sortedList.concat(deviceDisabledAlert)
                sortedList = sortedList.concat(deviceFHTWithoutStatus)
                sortedList = sortedList.concat(otherDevices)
                sortedList = sortedList.concat(offline)

                return sortedList.filter(device => {
                    return device.client.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                        || device.type.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                        || device.unity.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                        || device.unity.city.toLowerCase().includes(this.deviceSearch.toLowerCase())
                        || device.unity.state.toLowerCase().includes(this.deviceSearch.toLowerCase())
                        || device.hardwareId.toLowerCase().includes(this.deviceSearch.toLowerCase())
                        || device.description.toLowerCase().includes(this.deviceSearch.toLowerCase())
                })
            }
        },
        methods:{
            ok(){
                this.fault = false
            }
        }
    }
</script>

<style scoped>
    .deviceRouterLink{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0
    }
    .titleContainer{
        position: relative;
        margin-bottom: 5px;
        width: 40%;
        float: left;
    }
    .titleArea{
        margin: 12px 0 0 10px;
        width: 100%;
    }
    .titleText{
        position: absolute;
        width: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .titleBackgound{
        width: 700px;
    }
    .inputContainer{
        float: right;
        width: 35%;
    }
    .Device{
        position: relative;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        height: fit-content;
        text-decoration: none
    }
    .DeviceWithAlert{
        z-index: 12
    }
    .FakeDevice{
        display: flex;
        flex-direction: row;
        margin: 5px;
        flex-basis: 430px;/*Sempre acrescentar 10px da margem do device card*/
        flex-grow: 1;
    }
    .ResponsiveContentBoard{
        align-content: flex-start;
        padding: 0 5px;
    }
    .shadowSmooth{
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .4);
    }
    @media(max-width: 1450px){
        .titleText{
            width: 90%;
        }
        .titleBackgound{
            width: 600px;
        }
        .FakeDevice{
            flex-basis: 330px;/*Sempre acrescentar 10px da margem do device card*/
        }
    }
    @media(max-width: 1150px){
        .inputContainer{
            width: 100%;
        } 
    }
    @media(max-width: 1000px){
        .titleBackgound{
            width: 100%;
        }
        .titleContainer{
            width: 100%;
        }
    }
    @media(max-width: 600px){
        .titleArea{
            margin: 5px 0 0 0;
            width: 100%;
        }
        .inputSearch{
            margin: 0 0 5px 0;
        }
        .searchIcon{
            top: 6px;
        }
        .titleBackgound{
            width: 100%;
        }
        .titleText{
            width: 90%;
        }
        .titleContainer{
            width: 100%;
        }
        .inputContainer{
            width: 100%;
        }
        .FakeDevice{
            width: 100%;
        }
    }
</style>